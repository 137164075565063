<template>
	<tr>
		<td>{{ value.content }}</td>
		<td>
			<span>{{ value.type === 'multi' ? 'x' : '+' }}</span>
			<span>{{ value.value }} points</span>
		</td>
		<td>
			<span v-if="value.cooldown > 0">{{ value.cooldown }}min</span>
			<span v-else>No Cooldown</span>
		</td>
		<td>
			<v-dialog v-model="dialogEditValue" max-width="600px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" color="success" :disabled="!earn_rate_change">
						<v-icon>mdi-pencil</v-icon>		
					</v-btn>
				</template>

				<v-card class="pa-3">
					<v-form ref="form" @submit.prevent="UpdateValue(value, platform)" lazy-validation>
						<v-card-title>
							<span class="text-h5 pb-3">Edit {{ value.content }}</span>
						</v-card-title>

						<v-card-text>
							<v-row>

								<v-col cols="12" sm="12" md="6" lg="6" xl="6">
									<v-text-field
										outlined
										hide-spin-buttons
										clearable
										v-model="value.value"
										type="number"
										label="Multiplier"
										hint="Number Of Cast * Amount * Multiplier you set"
										required
									></v-text-field>
								</v-col>

								<v-col cols="12" sm="12" md="6" lg="6" xl="6">
									<v-text-field 
										outlined
										hide-spin-buttons
										clearable
										v-model="value.cooldown"
										type="number"
										label="Cooldown time in minutes"
										required
									></v-text-field>
								</v-col>

								<v-col cols="12" v-if="value.extra_muls && value.extra_muls.length > 0">
									<v-text-field
										outlined
										hide-spin-buttons
										clearable
										v-for="(extra) in value.extra_muls" :key="extra.name"
										v-model="extra.value"
										type="number"
										:label="capitalizeFirstLetter(extra.name) + ' multiplier'"
										required
									></v-text-field>
								</v-col>

							</v-row>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="dialogEditValue = false">Close</v-btn>
							<v-btn 
								text 
								color="blue darken-1" 
								type="submit"
								:loading="loadingEditValue" 
								:disabled="loadingEditValue"
							>Save</v-btn>
						</v-card-actions>
					</v-form>
				</v-card>
			</v-dialog>
		</td>
	</tr>
</template>

<script>
export default {
	props: ['value', 'earn_rate_change', 'platform'],

	data() {
		return {
			dialogEditValue: false,
			loadingEditValue: false,
		}
	},

	methods: {
		capitalizeFirstLetter: function (string) {
			return string.slice(0,1).toUpperCase() + string.slice(1);
		},

		UpdateValue: async function(value, platform) {
			try {
				this.loadingEditValue = true;

				const request = await fetch(this.$store.state.base_url + '/earn_rates/update', {
					method: 'PUT',
					body: JSON.stringify({
						earn_rate: value,
						platform: platform
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});
				const res = await request.json();

				if (request.ok) {
					if (res.isValueUpdated) {
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Earn Rate updated successfully!'
						});

						this.dialogEditValue = false;
						this.$emit('EarnRateEdit');
					}
					else if (res.isValueNotChanged) {
						this.$notify({
							group: 'main',
							type: 'warn',
							title: this.$store.state.app_name,
							text: 'Earn Rate is already saved! No changes made.'
						});

						this.dialogEditValue = false;
					}
					else if (res.error) {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else if (request.status === 500) {
					throw res.message;
				}
				else {
					if (Array.isArray(res)) {
						res.forEach(err => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: err.error
							});
						});
					}

					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error
				});
			}
			finally {
				this.loadingEditValue = false;
			}
		}
	},
}
</script>
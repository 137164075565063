<template>
	<v-col cols="12" class="d-flex flex-column align-self-stretch pa-0">
		<v-card flat tile :loading="valuesLoading" class="fill-height">
			<v-row class="pa-10">
				<h3>Stream Points Earn Rates</h3>
			</v-row>

			<v-col v-for="(earn_rates) in earn_rates" :key="earn_rates.platform">
				<h4 class="px-10">{{ capitalizeFirstLetter(earn_rates.platform) }}</h4>

				<v-simple-table class="pa-4">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Earning action</th>
								<th class="text-left">Points Earned</th>
								<th class="text-left">Cooldown</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<EditEarnRates
								v-for="(value) in earn_rates.earn_rates" 
								:key="value.id"
								:value="value"
								:platform="earn_rates.platform"
								:earn_rate_change="earn_rate_change"
								@EarnRateEdit="fetchValues"
							/>	
						</tbody>
					</template>
				</v-simple-table>
			</v-col>
		</v-card>
	</v-col>
</template>

<script>
import EditEarnRates from './EditEarnRates.vue';

export default {
	components: {
		EditEarnRates
	},

	data() {
		return {
			valuesLoading: true,
			earn_rates: [],
			earn_rate_change: false,
		}
	},

	async mounted() {
		await this.fetchValues();
	},

	beforeDestroy() {
		this.earn_rates = [];
		this.earn_rates_changes = false;
		this.valuesLoading = false;
	},

	methods: {
		capitalizeFirstLetter: function (string) {
			return string.slice(0,1).toUpperCase() + string.slice(1);
		},

		fetchValues: async function() {
			this.valuesLoading = true;

			try {
				const request = await fetch(this.$store.state.base_url + '/earn_rates', {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.values) {
						this.earn_rates = res.values;
						this.earn_rate_change = res.earn_rates_changes;
					}
				}
				else if (request.status === 500) {
					throw res;
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message
				});
			}
			finally {
				this.valuesLoading = false;
			}
		},
	},
}
</script>